// extracted by mini-css-extract-plugin
export var alignLeft = "B_rd d_bG";
export var alignCenter = "B_bP d_bD";
export var alignRight = "B_rf d_bH";
export var textAlignLeft = "B_t2";
export var textAlignCenter = "B_t3";
export var textAlignRight = "B_t4";
export var hoursInnerWrapperAlt = "B_t5 d_fb d_P d_Z d_bz d_bD d_bP d_dj";
export var hoursInnerWrapperLeft = "B_t6 d_fc d_fb d_P d_Z d_bz d_bD d_bP d_b5 d_dj";
export var hoursText = "B_t7 d_dw";
export var titleMargin = "B_t8 d_cw";
export var hoursInnerInnerWrapper = "B_t9 d_cz";