// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "K_wT d_bD";
export var storyRowWrapper = "K_hx d_hx d_bK";
export var storyLeftWrapper = "K_wV d_bz d_bP";
export var storyWrapperFull = "K_wW d_cD";
export var storyWrapperFullLeft = "K_qZ d_cD d_bz d_bP d_bD";
export var contentWrapper = "K_mv d_hy";
export var storyLeftWrapperCenter = "K_wX d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "K_wY d_hF";
export var storyHeader = "K_wZ d_hD d_w d_cs";
export var storyHeaderCenter = "K_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "K_hB d_hB d_by d_dw";
export var storyBtnWrapper = "K_w0 d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "K_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "K_rj d_fg d_Z";
export var imageWrapperFull = "K_w1 d_w d_H d_bf d_Z";
export var SubtitleSmall = "K_pd C_pd C_vb C_vn";
export var SubtitleNormal = "K_pf C_pf C_vb C_vp";
export var SubtitleLarge = "K_pg C_pg C_vb C_vq";
export var textLeft = "K_dv";
export var textCenter = "K_dw";
export var textRight = "K_dx";