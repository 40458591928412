// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "z_tK d_gv d_cs";
export var heroHeaderCenter = "z_gw d_gw d_cs d_dw";
export var heroHeaderRight = "z_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "z_tL d_gr d_cw";
export var heroParagraphCenter = "z_gs d_gs d_cw d_dw";
export var heroParagraphRight = "z_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "z_tM d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "z_tN d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "z_tP d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "z_tQ d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "z_tR d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "z_tS C_tS";
export var heroExceptionNormal = "z_tT C_tT";
export var heroExceptionLarge = "z_tV C_tV";
export var Title1Small = "z_tW C_tW C_vb C_vc";
export var Title1Normal = "z_tX C_tX C_vb C_vd";
export var Title1Large = "z_tY C_tY C_vb C_vf";
export var BodySmall = "z_tZ C_tZ C_vb C_vv";
export var BodyNormal = "z_t0 C_t0 C_vb C_vw";
export var BodyLarge = "z_t1 C_t1 C_vb C_vx";