// extracted by mini-css-extract-plugin
export var alignLeft = "w_rd d_fp d_bG d_dv";
export var alignCenter = "w_bP d_fq d_bD d_dw";
export var alignRight = "w_rf d_fr d_bH d_dx";
export var FAQMainHeader = "w_kt d_kt d_w d_by";
export var FAQComponentHeader = "w_kz d_kz d_bx";
export var FAQComponentParagraph = "w_kv d_kv d_cD";
export var FAQComponentsWrapper = "w_kr d_kr d_cn d_cL d_cR d_c2 d_cX d_dB";
export var FAQComponentsWrapperClean = "w_ks d_ks d_cn d_cL d_cR d_c0 d_dB";
export var accordion__body = "w_kB d_kB d_by";
export var accordion__bodyHidden = "w_kD d_kD d_bw d_dl";
export var FAQDivider = "w_kq d_kq";
export var accordionWrapper = "w_s0 d_cg";
export var accordion__item = "w_s1 d_cL";
export var accordion_arrow = "w_kH d_kH";
export var accordion_arrow_open = "w_kK d_kK";
export var accordion_arrow_2 = "w_kM d_kM";
export var accordion_arrow_2_open = "w_kP d_kP";
export var Subtitle2Small = "w_s2 C_s2 C_vb C_vr";
export var Subtitle2Normal = "w_s3 C_s3 C_vb C_vs";
export var Subtitle2Large = "w_s4 C_s4 C_vb C_vt";