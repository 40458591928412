// extracted by mini-css-extract-plugin
export var articleWrapper = "s_nB";
export var searchResult = "s_r1 s_nB";
export var line = "s_fk d_fk d_cY";
export var articleTitle = "s_r2 d_cq";
export var articleText = "s_nC d_cs";
export var authorImage = "s_ln d_ln d_b7 d_Z d_ct";
export var authorText = "s_n2 d_ch d_ct";
export var textCenter = "s_dw d_dw";
export var searchWrapper = "s_r3 d_bz d_bP d_dr d_cl d_Z";
export var searchInput = "s_r4 d_cF d_w d_b4";
export var searchBtn = "s_r5 d_b1 d_bC d_bP d_0 d_cD d_dB";
export var text = "s_pc d_cr";
export var elementWrapper = "s_my d_cl";
export var select = "s_r6 d_w d_b4";
export var articleImageWrapper = "s_nT d_cs d_Z d_V";
export var articleImageWrapperIcon = "s_nV d_cs d_Z d_V";
export var articleImageWrapperColumns = "s_r7 d_cs d_fh d_Z";
export var contentPadding = "s_r8 d_cS";
export var otherPadding = "s_r9 d_cM";
export var paginationWrapper = "s_sb";
export var pagination = "s_sc";
export var disabledPagination = "s_sd";
export var breakMe = "s_sf";
export var activePage = "s_sg";
export var next = "s_sh";
export var pages = "s_sj";
export var searchTitle = "s_sk d_cr";
export var categoryTitle = "s_sl d_cr d_Z";
export var searchText = "s_sm d_ct";
export var bold = "s_sn";
export var field = "s_sp";
export var dateTag = "s_nL d_l d_cr d_dq";
export var icon1 = "s_nM d_cP";
export var icon2 = "s_nN d_cP d_cJ";
export var tagBtn = "s_nP d_b1 d_cD d_dp d_dB";
export var linksWrapper = "s_n5 d_ch d_dw";
export var header = "s_nD d_w d_Z";
export var headerInner = "s_sq";
export var headerImage = "s_sr d_bd d_0 d_8 d_7 d_4 d_9";
export var separator = "s_n1 d_cY";
export var resultImage = "s_ss";