// extracted by mini-css-extract-plugin
export var alignLeft = "L_rd d_fp d_bG d_dv";
export var alignCenter = "L_bP d_fq d_bD d_dw";
export var alignRight = "L_rf d_fr d_bH d_dx";
export var testimonialsContainer = "L_w2 d_dW";
export var testimonialsContainerFull = "L_w3 d_dT";
export var testimonialsMainHeader = "L_jw d_jw d_w d_cw";
export var testimonialsComponentParagraph = "L_jy d_jy";
export var testimonialsComponentSmall = "L_jx d_jx";
export var testimonialsComponentsWrapper = "L_js d_js d_y d_w d_cv d_b3 d_b5";
export var testimonialsComponentsWrapperDesign2 = "L_jt d_jt d_y d_w d_cv d_b3 d_b5 d_bC";
export var testimonialsComponentsWrapperDesign3 = "L_jv d_jv d_y d_w d_H d_b5 d_bT d_bz d_bJ d_bP d_bD";
export var testimonialsBackgroundColor = "L_w4 d_d7";
export var colEqualHeight = "L_w5 d_bz";
export var testimonialsColumnWrapper = "L_jz d_jz d_b3";
export var testimonialsImageWrapper = "L_jp d_jp d_w d_Z";
export var testimonialsImageWrapperDesign2 = "L_jq d_jq d_r d_Z d_bx";
export var testimonialsImageWrapperDesign3 = "L_jr d_jr d_Z d_bz";
export var design2TextWrapper = "L_w6 d_bC d_bP d_bJ";
export var design3 = "L_w7 d_cD d_H";
export var imageDesign2 = "L_w8";
export var SmallSmall = "L_sK C_sK C_vb C_vy";
export var SmallNormal = "L_sL C_sL C_vb C_vz";
export var SmallLarge = "L_sM C_sM C_vb C_vw";
export var exceptionWeight = "L_tJ C_vD";