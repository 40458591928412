// extracted by mini-css-extract-plugin
export var alignDiscLeft = "y_th d_fp d_bG d_dv";
export var alignLeft = "y_rd d_fp d_bG d_dv";
export var alignDiscCenter = "y_tj d_fq d_bD d_dw";
export var alignCenter = "y_bP d_fq d_bD d_dw";
export var alignDiscRight = "y_tk d_fr d_bH d_dx";
export var alignRight = "y_rf d_fr d_bH d_dx";
export var footerContainer = "y_tl d_dW d_bW";
export var footerContainerFull = "y_tm d_dT d_bW";
export var footerHeader = "y_kf d_kf";
export var footerTextWrapper = "y_tn d_w";
export var footerDisclaimerWrapper = "y_km d_km d_ck";
export var badgeWrapper = "y_tp d_w d_bz d_bP d_bF d_bJ";
export var verticalTop = "y_tq d_bz d_bJ d_bN d_bL";
export var wide = "y_tr d_cy";
export var right = "y_ts d_bK";
export var line = "y_fk d_fl d_cv";
export var badgeDisclaimer = "y_tt d_bC d_bP d_bJ";
export var badgeContainer = "y_tv d_bz d_bH d_bP";
export var badge = "y_tw";
export var padding = "y_tx d_c7";
export var end = "y_ty d_bH";
export var linkWrapper = "y_tz d_dB";
export var link = "y_mC d_dB";
export var colWrapper = "y_tB d_cx";
export var consent = "y_f d_f d_bC d_bP";
export var disclaimer = "y_tC d_bz d_bJ";
export var media = "y_tD d_bx d_dy";
export var itemRight = "y_tF";
export var itemLeft = "y_tG";
export var itemCenter = "y_tH";
export var exceptionWeight = "y_tJ C_vD";