// extracted by mini-css-extract-plugin
export var carouselContainer = "Q_xK d_w d_H d_bf d_Z";
export var carouselContainerCards = "Q_xL Q_xK d_w d_H d_bf d_Z";
export var carouselContainerSides = "Q_xM d_w d_H d_Z";
export var prevCarouselItem = "Q_xN d_w d_H d_0 d_k";
export var prevCarouselItemL = "Q_xP Q_xN d_w d_H d_0 d_k";
export var moveInFromLeft = "Q_xQ";
export var prevCarouselItemR = "Q_xR Q_xN d_w d_H d_0 d_k";
export var moveInFromRight = "Q_xS";
export var selectedCarouselItem = "Q_xT d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "Q_xV Q_xT d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "Q_xW Q_xT d_w d_H d_Z d_bf";
export var nextCarouselItem = "Q_xX d_w d_H d_0 d_k";
export var nextCarouselItemL = "Q_xY Q_xX d_w d_H d_0 d_k";
export var nextCarouselItemR = "Q_xZ Q_xX d_w d_H d_0 d_k";
export var arrowContainer = "Q_x0 d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "Q_x1 Q_x0 d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "Q_x2 Q_x1 Q_x0 d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "Q_x3 Q_x0 d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "Q_x4";
export var nextArrowContainerHidden = "Q_x5 Q_x3 Q_x0 d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "Q_kG d_0";
export var prevArrow = "Q_x6 Q_kG d_0";
export var nextArrow = "Q_x7 Q_kG d_0";
export var carouselIndicatorContainer = "Q_x8 d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "Q_x9 d_w d_bz d_bF";
export var carouselText = "Q_yb d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "Q_yc Q_yb d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "Q_yd d_b7";
export var carouselIndicator = "Q_yf Q_yd d_b7";
export var carouselIndicatorSelected = "Q_yg Q_yd d_b7";
export var arrowsContainerTopRight = "Q_yh d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "Q_yj d_0 d_bl d_bC";
export var arrowsContainerSides = "Q_yk d_0 d_bl d_bC";
export var smallArrowsBase = "Q_yl d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "Q_ym Q_yl d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "Q_yn Q_ym Q_yl d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "Q_yp Q_yl d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "Q_yq Q_yp Q_yl d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "Q_n9";
export var iconSmall = "Q_yr";
export var multipleWrapper = "Q_ys d_bC d_bF d_bf";
export var multipleImage = "Q_yt d_bC";
export var sidesPrevContainer = "Q_yv Q_ym Q_yl d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "Q_yw Q_ym Q_yl d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";