// extracted by mini-css-extract-plugin
export var socialContainer = "J_wF";
export var instagramLink = "J_wG";
export var socialWrapperLeft = "J_qz d_dC d_Z d_bz d_bJ d_bp d_bG";
export var socialContentContainer = "J_wH";
export var socialContentContainerFull = "J_wJ";
export var instagramContainer = "J_wK";
export var twitterContainer = "J_wL";
export var facebookContainer = "J_wM";
export var socialErrorContainer = "J_wN";
export var facebookContainerWide = "J_wP";
export var twitterContainerWide = "J_wQ";
export var socialParagraphCenter = "J_wR";
export var instaWrapper = "J_wS";
export var SubtitleSmall = "J_pd C_pd C_vb C_vn";
export var SubtitleNormal = "J_pf C_pf C_vb C_vp";
export var SubtitleLarge = "J_pg C_pg C_vb C_vq";
export var textLeft = "J_dv";
export var textCenter = "J_dw";
export var textRight = "J_dx";